/* eslint-disable */
// @ts-nocheck
import React, { useState, useEffect } from "react"

import HomePage from "src/componentsV2/NewHome"
import SEO from "../components/SEO"

function isMobile(): boolean {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ]

  return toMatch.some(toMatchItem => navigator.userAgent.match(toMatchItem))
}

const index = ({ ...props }) => {
  const [isMobileChecked, setIsMobileChecked] = useState<boolean>(false)
  useEffect(() => {
    if (window !== undefined && window.analytics) {
      if (isMobileChecked) return

      window.analytics.identify({
        Intercom: { hideDefaultLauncher: isMobile() },
      })

      setIsMobileChecked(true)
    }
  })

  return (
    <React.Fragment>
      <SEO />
      <HomePage {...props} />
    </React.Fragment>
  )
}

export default index
