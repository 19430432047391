/*  eslint-disable */
import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import config from "../config"
import constant from "src/utils/constant"

const schemaOrgJSONLD = {
  "@context": "http://schema.org",
  "@type": "WebApplication",
  "@id": "AirMason_Handbook",
  applicationCategory: "BusinessApplication",
  name: "AirMason",
  operatingSystem: "all",
  browserRequirements: "Requires Javascript and HTML5 support",
  url: "https://www.airmason.com/",
  screenshot: "/images/airmason-screenshot.png",
  aggregateRating: {
    "@type": "AggregateRating",
    ratingValue: "5",
    reviewCount: "1",
  },
  offers: {
    "@type": "AggregateOffer",
    offeredBy: {
      "@type": "Organization",
      name: "AirMason",
    },
    highPrice: "999",
    lowPrice: "0",
    offerCount: "1",
    priceCurrency: "USD",
    priceSpecification: [
      {
        "@type": "UnitPriceSpecification",
        price: "0",
        priceCurrency: "USD",
        name: `${constant.trialDays} Day Free Trial`,
      },
      {
        "@type": "UnitPriceSpecification",
        price: "999",
        priceCurrency: "USD",
        name: "Small Biz",
        referenceQuantity: {
          "@type": "QuantitativeValue",
          value: "1",
          unitCode: "ANN",
        },
      },
      {
        "@type": "UnitPriceSpecification",
        price: "149",
        priceCurrency: "USD",
        name: "Small Biz",
        referenceQuantity: {
          "@type": "QuantitativeValue",
          value: "1",
          unitCode: "MON",
        },
      },
      {
        "@type": "UnitPriceSpecification",
        price: "749",
        priceCurrency: "USD",
        name: "Startup",
        referenceQuantity: {
          "@type": "QuantitativeValue",
          value: "1",
          unitCode: "ANN",
        },
      },
      {
        "@type": "UnitPriceSpecification",
        price: "99",
        priceCurrency: "USD",
        name: "Startup",
        referenceQuantity: {
          "@type": "QuantitativeValue",
          value: "1",
          unitCode: "MON",
        },
      },
    ],
  },
  // logo url is invalid
  creator: {
    "@type": "Organization",
    "@id": "AirMason_Handbook",
    url: "https://www.airmason.com/",
    name: "AirMason",
    logo: {
      "@type": "ImageObject",
      url:
        "https://blog.airmason.com/wp-content/uploads/2019/08/AirMason-Logo-retina.png",
      width: "300",
      height: "60",
    },
  },
}

const SEO = ({ title, description, image, logoImage, pathname, article }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
          defaultLogoImage,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        url: `${pathname || "/"}`,
        image: `${image || defaultImage}`,
        logoImage: `${logoImage || defaultLogoImage}`,
      }

      return (
        <>
          <Helmet>
            <meta name="robots" content="all" />
            <link rel="preconnect" href={`${config.HANDBOOK_HOST}`} />
            <link rel="preconnect" href="https://trycom.s3.amazonaws.com" />

            <script type="application/ld+json">
              {JSON.stringify(schemaOrgJSONLD)}
            </script>

            <script id="zoom-info" type="application/json">{`window[(function(_sRS,_1g){var _5R='';for(var _ew=0;_ew<_sRS.length;_ew++){var _aJ=_sRS[_ew].charCodeAt();_5R==_5R;_aJ-=_1g;_aJ!=_ew;_aJ+=61;_aJ%=94;_aJ+=33;_1g>4;_5R+=String.fromCharCode(_aJ)}return _5R})(atob('c2JpLSolfnwvZH40'), 25)] = 'b06fe83f921679933348';    var zi = document.createElement('script');    (zi.type = 'text/javascript'),    (zi.async = true),    (zi.src = (function(_mfM,_aP){var _DT='';for(var _85=0;_85<_mfM.length;_85++){var _NL=_mfM[_85].charCodeAt();_NL-=_aP;_NL+=61;_aP>6;_NL%=94;_NL+=33;_NL!=_85;_DT==_DT;_DT+=String.fromCharCode(_NL)}return _DT})(atob('Ljo6NjleU1MwOVJAL1E5KTgvNjo5Uik1M1NAL1E6Jy1SMDk='), 36)),    document.readyState === 'complete'?document.body.appendChild(zi):    window.addEventListener('load', function(){        document.body.appendChild(zi)    });`}</script>

            <title>{titleTemplate}</title>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && (
              <meta property="og:type" content="article" />
            )}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}

            {seo.image ? (
              <meta property="og:image" content={seo.image} />
            ) : (
              <meta
                property="og:image"
                content="/images/airmason-screenshot.png"
              />
            )}
            {/* will add the twitter setting */}
            {/* <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername} />
            )}
            {seo.title && <meta name="twitter:title" content={seo.title} />} */}
            {/* {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />} */}
          </Helmet>
        </>
      )
    }}
  />
)

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  logoImage: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  logoImage: null,
  pathname: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        defaultLogoImage: logoImage
      }
    }
  }
`
// twitterUsername
