/* eslint-disable */
import styled from "styled-components"

export const HeroBannerContainer = styled.div`
  img {
    margin-bottom: -80px;
    margin-top: -20px;
    margin-left: 0px;
  }

  @media only screen and (min-width: 768px) {
    img {
      margin-left: 15px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    img {
      margin-bottom: 0px;
      margin-top: 50px;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    img {
      margin-bottom: -75px;
      margin-top: 0px;
    }
  }

  @media only screen and (min-width: 1200px) {
    img {
      margin-bottom: -175px;
      margin-top: -110px;
    }
  }
`

export const LeftWrapper = styled.div`
  width: 50%;
  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
  }
`

export const RightWrapper = styled.div`
  width: 50%;
  img {
    margin-bottom: -65px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  @media only screen and (max-width: 414px) {
    img {
      margin-left: -10px;
    }
  }
`
