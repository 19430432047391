import React, { useState } from "react"
import LazyLoad from "vanilla-lazyload"
import loadable from '@loadable/component'

import HomePageContext from "./context"
import { HomePageStyled } from "./style"

/**
 * https://blog.logrocket.com/seo-approach-async-loadable-components/#:~:text=This%20is%20because%20the%20components,content%20when%20indexing%20the%20site.
 
 * When Lazy loading components, initially they aren't crawl-able
 * 1. Static HTML is rendered
 * 2. React components are hydrated into the static HTML
 * 3. Because of the asynchronous components taking time to resolve, the current DOM is destroyed and only created again when it’s done loading
 * Solution: With SSR, the html is already there. It only needs to be retrieved and rendered.
 */
// const getRenderedContent = (value: string) => {
//   if (typeof window === 'undefined') return ''
//   const element = window.document.querySelector(`[data-loadable-component="${value}"]`);
//   return element ? element.innerHTML : '';
// }

import Header from "src/componentsV2/sections/Header"
import HeroBanner from "./HeroBanner"
import { TrustedByIndustryBrands } from "./TrustedByIndustryBrands"
import { Footer } from '../../sections/landing/index.js'

const ContactSalesModal = loadable(() => import("../ContactSalesModal"),
  { resolveComponent: (imported: any) => imported.default });

const AutoPolicyUpdates = loadable(() => import("./AutoPolicyUpdates"), { resolveComponent: (imported: any) => imported.default });

const DigitalHandbook = loadable(() => import("./DigitalHandbook"), { resolveComponent: (components: any) => components.DigitalHandbook });

const EverythingYouNeed = loadable(() => import("./EverythingYouNeed"), { resolveComponent: (components: any) => components.EverythingYouNeed });

const HomeFAQ = loadable(() => import("./HomeFAQ"), { resolveComponent: (components: any) => components.HomeFAQ });

const Cost = loadable(() => import("./Cost"), { resolveComponent: (imported: any) => imported.default });

const Integration = loadable(() => import("./Integration"), { resolveComponent: (imported: any) => imported.default });

const CreativeServices = loadable(() => import("./CreativeServices"), { resolveComponent: (imported: any) => imported.default });


const index = () => {
  const [isContactSalesOpenModal, setIsContactSalesOpenModal] = useState(false)
  const ref = React.useRef<any>(null)

  React.useEffect(() => {
    if (!ref.current && window !== undefined) {
      ref.current = new LazyLoad()
    }
    if (ref.current && window !== undefined) {
      ref.current.update()
    }
  }, [ref])

  const openContactSalesModal = () => {
    setIsContactSalesOpenModal(true)
  }

  return (
    <HomePageContext.Provider value={{ openContactSalesModal }}>
      <HomePageStyled>
        <Header isHomePage />
        <HeroBanner />
        <AutoPolicyUpdates fallback={<div />} />
        <TrustedByIndustryBrands />
        {/* <DigitalHandbook isHomePage fallback = {<div dangerouslySetInnerHTML={{  __html: getRenderedContent('digital-handbook')}} />} />
        <Cost fallback = {<div dangerouslySetInnerHTML={{  __html: getRenderedContent('cost-section')}} />} />
        <EverythingYouNeed fallback = {<div dangerouslySetInnerHTML={{  __html: getRenderedContent('everything-you-need')}} />} />
        <Integration fallback = {<div dangerouslySetInnerHTML={{  __html: getRenderedContent('sso-hris-integration')}} />} />
        <CreativeServices fallback = {<div dangerouslySetInnerHTML={{  __html: getRenderedContent('creative-services')}} />} />
        <HomeFAQ fallback = {<div dangerouslySetInnerHTML={{  __html: getRenderedContent('home-faq')}} />} />
        <Footer isHomePage fallback = {<div dangerouslySetInnerHTML={{  __html: getRenderedContent('footer')}} />} /> */}
        <DigitalHandbook isHomePage fallback={<div />} />
        <Cost fallback={<div />} />
        <EverythingYouNeed fallback={<div />} />
        <Integration fallback={<div />} />
        <CreativeServices fallback={<div />} />
        <HomeFAQ fallback={<div />} />
        <Footer isHomePage />
      </HomePageStyled>
      {isContactSalesOpenModal && (
        <ContactSalesModal
          onClose={() => setIsContactSalesOpenModal(false)}
          isOpen={isContactSalesOpenModal}
        />
      )}
    </HomePageContext.Provider>
  )
}

export default index
